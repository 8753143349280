import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";


import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: "https://1eb86c59dcac4e7a891936907771b4ab@o1200990.ingest.sentry.io/6349336",
  release: environment.releaseVersion,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    Sentry.browserApiErrorsIntegration({
      setTimeout: false
    })
  ],

  environment: environment.env,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  tracePropagationTargets: ["localhost", environment.apiUrl],

  /**
   * HUB-814?focusedCommentId=20418
   * @param event 
   * @param hint 
   * @returns 
   */
  beforeSend(event, hint) {
    if (hint?.originalException === "Timeout") return null;
    return event;
  }
});

if (environment.production) {
  enableProdMode();
}

//HUB-813: register service worker only if env is production or staging.
platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && (environment.production || environment.staging)) {
    navigator.serviceWorker.register('/custom-sw.js');
  }
}).catch(err => console.error(err));

// platformBrowserDynamic().bootstrapModule(AppModule).catch();